import axios, { AxiosInstance } from "axios";
import { LiveUpdate } from "@capawesome/capacitor-live-update";
import metaConfig from "../../../meta.json";
import * as Sentry from "@sentry/capacitor";
import { Capacitor } from "@capacitor/core";

export type Bundle = {
    branch: string;
    bundle: {
        version: string;
        url: string;
        hash: string;
    };
    signature: string;
    isActive: boolean;
}

export type BundleResponse = {
    bundle: Bundle;
};

export class CapacitorLiveUpdate {
    private api: AxiosInstance;

    constructor(baseUrl?: string) {
        this.api = axios.create({
            baseURL: baseUrl || 'https://api.kobogo.ng/',
            withCredentials: false,
        });
        // @ts-ignore
        window.LiveUpdate = LiveUpdate;
    }

    static async ready(): Promise<void> {
        await LiveUpdate.ready();
    }

    static async reset(): Promise<void> {
        await LiveUpdate.reset();
    }

    static async reload(): Promise<void> {
        await LiveUpdate.reload();
    }

    static async getUsedVersion(): Promise<string | null> {
        return (await LiveUpdate.getBundle()).bundleId;
    }

    static setBundleToUse = (bundleId: string) => {
        return LiveUpdate.setBundle({bundleId});
    }

    static getAllInstalledBundles = (): Promise<Array<string>> => {
        return LiveUpdate.getBundles().then(bundles => bundles?.bundleIds || []);
    }

    static downloadBundle = (downloadUrl: string, bundleId: string): Promise<void> => {
        return LiveUpdate.downloadBundle({
            url: downloadUrl,
            bundleId,
        });
    }

    static downloadBundleAndInstall = async (downloadUrl: string, version: string): Promise<(() => Promise<void>) | undefined> => {
        if (!downloadUrl || !version) {
            Sentry.captureException(`Trying to download and install an empty version: ${version}, url: ${downloadUrl}`);
            return;
        }

        if (!Capacitor.isNativePlatform()) {
            console.warn('Trying to download and install a bundle on a non-native platform');
        }

        const allInstalledBundles: string[] = await CapacitorLiveUpdate.getAllInstalledBundles();
        const usedVersion: string | null = await CapacitorLiveUpdate.getUsedVersion();

        /** если последняя актуальная версия не установлена - скачиваем, сделано для того чтобы вдруг у клиента уже скачана эта версия */
        if (allInstalledBundles.indexOf(version) === -1) {
            await CapacitorLiveUpdate.downloadBundle(downloadUrl, version);
        } else if (usedVersion === version) {
            Sentry.captureException(`Trying to install an already installed version: ${version}`);
        }

        await CapacitorLiveUpdate.setBundleToUse(version);

        /** Применяем обновление сразу вызвав reload. Нужно для новых пользователей скачавших свежий апп со стора. */
        return CapacitorLiveUpdate.reload;
    }

    getLatestVersion = async (channel: string): Promise<BundleResponse | undefined> => {
        return this.api.get<BundleResponse | undefined>('v3/mobile/app-bundle/latest', { params: { branch: channel }})
            .then(response => response.data)
            .catch(error => {
                console.warn(error);
                return undefined;
            });
    };

    getAllBranches = () => this.api.get('v3/mobile/app-bundle/branches');

    getAllVersions = (channel: string = 'master') => this.api.get('v3/mobile/app-bundle/versions', {
        params: { branch: channel }
    });

    getBundle = (channel: string, branch = 'master') => this.api.get('v3/mobile/app-bundle/bundle', {
        params: { channel, branch } //????
    });
}


/** Если забыть обновить channel в meta.json и выгрузить обновление в google play - то все изменения будут потеряны Потому что приложение будет по-прежнему грузить версию - скачанную через liveUpdates. **/
export async function initLiveUpdate(): Promise<void> {
    await CapacitorLiveUpdate.ready();
    const CapacitorLiveUpdateInstance: CapacitorLiveUpdate = new CapacitorLiveUpdate();

    const latestBundleFromServer: BundleResponse | undefined = await CapacitorLiveUpdateInstance.getLatestVersion(metaConfig.channel);
    const latestUrlFromServer: string | undefined = latestBundleFromServer?.bundle?.bundle?.url;
    const latestVersionFromServer: string | undefined = latestBundleFromServer?.bundle?.bundle?.version;

    const currentLiveUpdateVersion: string | null = await CapacitorLiveUpdate.getUsedVersion();

    if (currentLiveUpdateVersion !== null) {
        {
            const scope = Sentry.getCurrentScope();
            scope.setExtra("LiveUpdateChannel", metaConfig.channel);
            scope.setExtra("LiveUpdateBundleId", currentLiveUpdateVersion);
        };
    }
    /** это значит что обновлений для этого канала нет
     * надо сбросить все текущие обновления, потому что сейчас какое-то установлено*/
    if (latestBundleFromServer === undefined && currentLiveUpdateVersion !== null) {
        await CapacitorLiveUpdate.reset();
        {
            const scope = Sentry.getCurrentScope();
            scope.setExtra("LiveUpdateChannel", null);
            scope.setExtra("LiveUpdateBundleId", null);
        };
        await CapacitorLiveUpdate.reload();
        return;
    }

    /** это значит что обновлений для этого канала нет и сейчас никакое liveUpdate не установлено */
    if (latestBundleFromServer === undefined) {
        {
            const scope = Sentry.getCurrentScope();
            scope.setExtra("LiveUpdateChannel", null);
            scope.setExtra("LiveUpdateBundleId", null);
        };
        return;
    }

    /** это значит что данные с сервера пришли не корректные или текущая версия уже стоит */
    if (!latestVersionFromServer || !latestUrlFromServer || currentLiveUpdateVersion === latestVersionFromServer) {
        return;
    }

    /** если мы уже тут - значит нужно скачать и установить версию. */
    const reloadFn = await CapacitorLiveUpdate.downloadBundleAndInstall(latestUrlFromServer, latestVersionFromServer);

    if (typeof reloadFn === "function") {
        await reloadFn();
    }
}
