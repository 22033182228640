import * as Sentry from "@sentry/capacitor";

export type ConfigEnvironment = "production" | "development" | "test";
export type ConfigItem = {
    baseUrl: string | undefined;
    sentry?: {
        dsn: string;
        environment: string;
    };
    logRocket?: string;
    liveChatLicenseId?: number;
    appsFlyerKey?: string;
    kochavaGUID?: string;
    adjustAppToken?: string;
    UETCounterId?: string;
};
export type Config = {
    [k in ConfigEnvironment]: ConfigItem;
};
export type CurrentConfig = {
    environment: ConfigEnvironment;
    config: ConfigItem;
};

export const TIME_TO_ACTIVATE_ANOTHER_ENV_MODE = 2000;
export const MAX_DOUBLE_CLICKS_TO_ACTION = 3;

export const config: Config = {
    production: {
        baseUrl: "https://api.kobogo.ng/",
        sentry: {
            dsn: "https://14db7cc0045b445bb30e0deb2ee77d4c@o118616.ingest.sentry.io/4505121406058496",
            environment: "production",
        },
        liveChatLicenseId: 15444168,
        logRocket: "rajzfo/kobogo-nigeria",
        appsFlyerKey: "8bsqwqVp57NvErr8zqehxa",
        kochavaGUID: "kokobogo-s0oyiut0",
        adjustAppToken: "l0jescpsf94w",
        UETCounterId: "151010076",
    },
    development: {
        /**
         * Да, мы отдаем себе отчет, что в случае сборки с окружением отличным от development
         * значение BASE_URL будет не корректным, но оно никогда не отдастся в getCurrentConfig()
         * отличное оно будет по причине другого файла конфига используемого dotenv
         */
        baseUrl: process.env.BASE_URL,
        adjustAppToken: "l0jescpsf94w",
    },
    test: {
        baseUrl: "https://api.test.kobogo.ng/",
        sentry: {
            dsn: "https://14db7cc0045b445bb30e0deb2ee77d4c@o118616.ingest.sentry.io/4505121406058496",
            environment: "test",
        },
        appsFlyerKey: "8bsqwqVp57NvErr8zqehxa",
        adjustAppToken: "l0jescpsf94w",
    },
};

export function getCurrentConfig(): CurrentConfig {
    const environment: ConfigEnvironment =
        <ConfigEnvironment>localStorage.getItem("environment") ||
        process.env.NODE_ENV;

    if (!(environment in config)) {
        localStorage.removeItem("environment");
        Sentry.captureException(`getCurrentConfig() - unsupported environment: ${environment}`);
    }

    setCurrentEnv(environment);

    return {
        environment,
        config: config[environment],
    };
}

export function setCurrentEnv(environment: ConfigEnvironment): void {
    localStorage.setItem("environment", environment);
}
