import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import {Device} from "@capacitor/device";
import {ConfigItem, getCurrentConfig} from "./config";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import metaJson from "../../meta.json";
import { CapacitorLiveUpdate } from "../modules/capacitor/live-update";
import { Capacitor } from "@capacitor/core";

const currentConfig: ConfigItem =  getCurrentConfig().config;
const sentryDsn: string | undefined =  currentConfig.sentry?.dsn;
const logRocket: string | undefined =  currentConfig.logRocket;
const sentryEnvironment: string | undefined =  currentConfig.sentry?.environment;

/** Hack for web*/
const LiveUpdateUsedVersionPromise: Promise<string | null> = Capacitor.isNativePlatform() ? CapacitorLiveUpdate.getUsedVersion() : new Promise((resolve) => resolve(null));

Promise.all([LiveUpdateUsedVersionPromise, Device.getId()]).then(([version, {identifier}]): void => {
    const releaseName = `${metaJson.channel.replace('/','-')}-${version || metaJson.version}`;

    if (sentryDsn) {
        Sentry.init({
            // nc-mobile
            dsn: sentryDsn,
            environment: sentryEnvironment,
            release: releaseName,
            sampleRate: 0.1,
        }, SentryReact.init);

        Sentry.setUser({
            id: identifier,
        });
    }

    if (logRocket && Math.random() <= 0.1) {
        LogRocket.init(logRocket, {release: releaseName});
        setupLogRocketReact(LogRocket);
        LogRocket.getSessionURL(sessionURL => {
            if (!sentryDsn) {
                return;
            }
            Sentry.getCurrentScope().setExtra("sessionURL", sessionURL);
        });
    }
});
